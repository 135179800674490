//Contentpage Template 

import React from "react"
import {Link} from "react-router-dom"

const ContentPage = ({children}) => {
    return(
        <div className="wrapper contentPage rounded position-relative" style={{marginTop: "2rem", padding: "1rem", paddingTop: "3rem"}}>
            <Link to={"/"} className="position-absolute" style={{right: "1rem", top: "1rem"}}>
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0001 22.0001C7.95361 22.0024 4.30455 19.5659 2.75572 15.8276C1.20689 12.0893 2.0636 7.78609 4.92606 4.92601C7.45335 2.39871 11.137 1.41169 14.5893 2.33675C18.0417 3.2618 20.7383 5.9584 21.6633 9.41075C22.5884 12.8631 21.6014 16.5467 19.0741 19.074C17.2015 20.955 14.6542 22.0087 12.0001 22.0001ZM4.00006 12.172C4.04737 16.5733 7.64116 20.1096 12.0426 20.086C16.444 20.0622 19.9996 16.4875 19.9996 12.086C19.9996 7.68452 16.444 4.10978 12.0426 4.08601C7.64116 4.06247 4.04737 7.59877 4.00006 12V12.172ZM9.40906 16L8.00006 14.59L10.5901 12L8.00006 9.41001L9.41006 8.00001L12.0001 10.59L14.5901 8.00001L16.0001 9.41001L13.4101 12L16.0001 14.59L14.5911 16L12.0001 13.41L9.41006 16H9.40906Z" fill="grey"></path>
                    </svg>
            </Link>

            <div className="content">
                {children}

            </div>
        </div>
    )
}

export default ContentPage;