import React from "react";


const ImageSource = ({auth}) =>{
    return(
        <>
        {!auth.status &&
            <span className="imgSource">Foto: <a href="https://iStock.com/curtoicurto" target="_blank" rel="noopener noreferrer">iStock.com/curtoicurto</a></span>
        }
        </>
    );
}

export default ImageSource;