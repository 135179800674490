import React, {useState} from "react";
import Vimeo from "@u-wave/react-vimeo";
import Loader from "./loader";

const Player = ({mediaID}) => {
    
    const [isloading, setIsloading] = useState(true);

    const VimeoInstance = () => (<Vimeo
    video={mediaID}
    color="fff"
    autoplay
    responsive
    onReady={() => {console.log("setloading"); setIsloading(false)}}
    className={`${isloading && "d-none "} shadow`}
    showTitle={false}
/>)

    return(
        <div className={`${isloading && "loading"} vimeoplayer relative`}>
            {isloading &&
                <Loader width="100px" /> 
            }
            <VimeoInstance />

        </div>
    )
}

export default Player;
