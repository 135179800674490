import React, {useRef} from "react";
import {Link} from "react-router-dom"
import Modal from "react-bootstrap/modal";
import Button from "react-bootstrap/button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert"

import Loader from "./loader"


import { motion, AnimatePresence } from "framer-motion"

const Login = ({usercode,setUsercode, auth, setAuth, handleLogin, setMediaID}) => {

    const consent = useRef();

    return(
        <div className="login">
            <Modal.Dialog className="shadow" >
                {auth.loading ? <Loader width="75px" /> : null}
                <form action="/" onSubmit={(e) => {handleLogin(e, usercode, auth, setAuth, setMediaID, consent.current.checked )}}>
                <Modal.Header>
                    <Modal.Title>Kennwort </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <InputGroup size="lg">
                        <input type="text" className="form-control" onChange={(event) => {setUsercode({usercode: event.target.value})}} />
                        
                    </InputGroup>
                    <Form.Text muted className="" >
                        Bitte geben Sie Ihr Kennwort ein. Kein Kennwort? <a href="tel:0511 1265-123">0511 1265-123</a>
                    </Form.Text> 
                    <InputGroup className="py-3 d-flex">
                        <label htmlFor="consent" style={{display: "inline"}}>
                            <input type="checkbox" ref={consent} style={{marginRight: ".5rem"}} className="fs-6" type="checkbox" id="consent" name="consent" />
                            Mit meinem Klick auf 'Absenden' akzeptiere ich die Cookies, die für die Funktion der Website notwendig sind. <Link to="/datenschutz">Datenschutzerklärung</Link>
                        </label>
                    </InputGroup>
                 
                    <AnimatePresence initial={false}>
                        {auth.error &&
                            <motion.div layout="position"
                                initial={{ opacity: 0, height: "0vh"}}
                                animate={{ opacity: 1, height: "100%"}}
                                exit={{ opacity: 0 }} >
                                <Alert variant="primary" className="m-0">
                                    {auth.error}
                                </Alert>
                            </motion.div>
                        }
                    </AnimatePresence>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type="submit" >Absenden</Button>
                </Modal.Footer>
                </form>
            </Modal.Dialog>
        </div>  
    )
}

export default Login;